import {
  AspectRatio,
  Box,
  Container,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Select,
  SimpleGrid,
  Stack,
  StackDivider,
  Switch,
  Text,
  useToken,
} from '@chakra-ui/react'
import CityBubbles from 'components/CityBubbles'
import useHeader from 'contexts/header/useHeader'
import round from 'lodash/round'
import React, { createElement, useEffect, useMemo, useState } from 'react'
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import getCityScores from 'utils/getCityScores'

const cityScores = getCityScores()

const categoryMax = Object.values(cityScores).reduce(
  (theMax, { categories }) => {
    return Object.keys(categories).reduce((theMax, category) => {
      theMax[category] = Math.max(
        theMax[category] || 0,
        categories[category].score
      )
      return theMax
    }, theMax)
  },
  {}
)

const HorizonBarChart = ({ data }) => {
  const [pink] = useToken('colors', ['pink.500'])

  return (
    <AspectRatio ratio={1}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          layout="vertical"
          width={500}
          height={500}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 40,
            bottom: 5,
          }}
        >
          <XAxis type="number" domain={[0, 100]} />
          <YAxis dataKey="label" type="category" />
          <Tooltip />
          <Bar dataKey="score" fill={pink} />
        </BarChart>
      </ResponsiveContainer>
    </AspectRatio>
  )
}

const SwitchToggle = ({ isChecked, onChange, id, label, rightLable }) => (
  <FormControl display="flex" alignItems="center" minW="10em">
    <FormLabel htmlFor={id} mb="0">
      {label}
    </FormLabel>
    <Switch
      id={id}
      isChecked={isChecked}
      colorScheme="pink"
      onChange={(e) => onChange(e.target.checked)}
    />
    {rightLable && (
      <FormLabel htmlFor={id} ml="3" mb="0">
        {rightLable}
      </FormLabel>
    )}
  </FormControl>
)

const Cities = () => {
  const { setHideHeader } = useHeader()

  const [ascending, setAscending] = useState(0)
  const [isAvg, setIsAvg] = useState(true)
  const [isBubble, setIsBubble] = useState(true)

  useEffect(() => {
    setHideHeader(true)
  }, [])

  const sorted = useMemo(
    () =>
      Object.entries(cityScores).sort(
        (a, b) => (a[1].score - b[1].score) * (ascending ? 1 : -1)
      ),
    [ascending]
  )

  return (
    <Container py="4" as={Stack} maxW="container.lg">
      <HStack>
        <HStack divider={<StackDivider />}>
          <SwitchToggle
            id="avg"
            label="加總"
            rightLable="平均值"
            isChecked={isAvg}
            onChange={setIsAvg}
          />
          <SwitchToggle
            id="bubble"
            label="長條"
            rightLable="泡泡"
            isChecked={isBubble}
            onChange={setIsBubble}
          />
        </HStack>
        <Box flex="1" />
        <Select width="6em" onChange={(e) => setAscending(+e.target.value)}>
          <option value="0">降冪</option>
          <option value="1">升冪</option>
        </Select>
      </HStack>
      <SimpleGrid columns={2} spacing={6}>
        {sorted.map(([city, { score, categories, count }], i) => {
          let chartData = Object.entries(categories)
            .filter(([category]) => Boolean(category))
            .map(([category, { score, details }]) => ({
              label: `${category}(${details.length})`,
              category,
              score: round(score / (isAvg ? details.length : 1), isAvg ? 2 : 0),
            }))
          if (isBubble) {
            chartData = chartData.reduce((acc, { category, score }) => {
              acc[category] = { score }
              return acc
            }, {})
          } else {
            chartData = chartData.sort((a, b) =>
              isBubble ? 0 : (a.score - b.score) * (ascending ? 1 : -1)
            )
          }
          return (
            <Stack key={i} divider={<Divider borderColor="gray.400" />}>
              <HStack>
                <Box
                  flex="1"
                  textAlign="left"
                  fontSize="2xl"
                  fontWeight="medium"
                >
                  {city}
                </Box>
                <Box pr="4">
                  score:{` `}
                  <Text as="span" fontWeight="medium">
                    {round(score / (isAvg ? count : 1), isAvg ? 2 : 0)}
                  </Text>
                </Box>
              </HStack>
              {createElement(isBubble ? CityBubbles : HorizonBarChart, {
                categoryMax: !isAvg && categoryMax,
                city,
                data: chartData,
              })}
            </Stack>
          )
        })}
      </SimpleGrid>
    </Container>
  )
}

export default Cities
